<template>
  <div>
    <portal to="title-page">
      <h1>Cupon: {{cupon ? cupon.name : ''}}</h1>
    </portal>
    <v-card class="mx-auto elevation-1">
      <v-card-title>
        <v-icon large left>{{mdiImageMultiple}}</v-icon>
        <span class="title font-weight-light">Imagenes</span>
        <v-btn
          small
          @click="$router.push({path:`/backend/admin/cupones/${$route.params.Id}/edit`})"
          color="primary"
          class="ml-auto"
        >
          <v-icon>{{mdiPencil}}</v-icon>Informacion
        </v-btn>
        <v-btn small class="ml-2" @click="$router.go(-1)">
          <v-icon>{{mdiArrowLeft}}</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          v-if="image != null"
          :headers="tableHeaders"
          :items="image"
          item-key="id"
          fixed-header
          class="elevation-1"
        >
          <!-- show-select -->
          <!-- :single-select="singleSelect" -->
          <template v-slot:top>
            <v-progress-linear v-if="uploadValue !== null" :value="uploadValue"></v-progress-linear>
            <div class="table-top-bar">
              <loading :active.sync="myLoading.isLoading" :is-full-page="myLoading.fullPage"></loading>
              <div class="d-flex">
                <div class="file-input-width">
                  <v-file-input
                    prepend-icon="mdi-camera"
                    v-model="imageup"
                    accept="image/*"
                    label="File input"
                  ></v-file-input>
                </div>
                <div>
                  <v-btn color="primary" :disabled="!imageup" class="mt-3" @click="upload">Subir</v-btn>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:item.img="{ item }">
            <v-img :src="item.url" aspect-ratio="1.7"></v-img>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon class="mr-2" color="red" @click="deleteItem(item)">{{mdiDelete}}</v-icon>
            <a :href="item.url" target="_blank">
              <v-icon class="mr-2" >{{mdiOpenInNew}}</v-icon>
            </a>
          </template>
        </v-data-table>
      </v-card-text>
      <!-- <v-card-actions>
      </v-card-actions>-->
    </v-card>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="-1">
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn color text v-bind="attrs" @click="snackbar.show = false">
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import CuponImage from "@/models/ImageCupon.js";
import Cupon from "@/models/Cupones.js";
import Loading from "vue-loading-overlay";
import firebase from 'firebase/app';
import 'firebase/firestore';

import {
  mdiDelete,
  mdiClose,
  mdiArrowLeft,
  mdiPencil,
  mdiImageMultiple,
  mdiOpenInNew,
} from "@mdi/js";

//import lodash from 'lodash';
export default {
  name: "CuponImages",
  components: {
    Loading,
  },
  data() {
    return {
      mdiDelete: mdiDelete,
      mdiClose: mdiClose,
      mdiArrowLeft: mdiArrowLeft,
      mdiPencil: mdiPencil,
      mdiImageMultiple: mdiImageMultiple,
      mdiOpenInNew: mdiOpenInNew,
      ////////////////////////////////////////////////////////////////////////////
      //languageModel:new Language,
      cuponModel: new Cupon(),
      cuponImageModel: new CuponImage(),
      cuponId: null,
      cupon: null,
      uploadValue: null,
      image: [],
      imageup: null,
      items: [],
      date : firebase.firestore.Timestamp.fromDate(new Date),
      tableHeaders: [
        {
          text: "Imagen",
          align: "start",
          sortable: true,
          value: "img",
          width: "200px",
        },
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Actions", value: "actions", sortable: false, align: "end" },
      ],
      ref: null,
      snackbar: {
        show: false,
        color: "",
        message: "",
      },
      myLoading: {
        isLoading: false,
        fullPage: false,
      },
    };
  },
  methods: {
    upload() {
      let loading = this.$loading.show();
      this.cuponImageModel.addImg(this.cuponId, this.imageup).then(() => {
        // get url of image
        this.cuponImageModel.getUrlImg(this.cuponId, this.imageup.name).then((url) => {
          let urlImg = url;
          this.cuponImageModel.foundNameImageRepit(this.cuponId, this.imageup.name).get().then((resp) =>{
            let respImg = resp;
            if(respImg.empty){
              // save img in subcollection of cupon 
              this.cuponImageModel.addImgcollec(this.cuponId, urlImg, this.imageup.name, this.date).then(() => {
                this.imageup = [];
                loading.hide();
                this.snackbar.show = true;
                this.snackbar.color = "green";
                this.snackbar.message = `La imagen del cupon se agrego correctamente `;
              })

            }
            else{
              this.imageup = [];
              Loading.hide();
              this.snackbar.show = true;
              this.snackbar.color = "red";
              this.snackbar.message =
                "Error, la imagen del cupon ya existe, por favor agrega una imagen distinta";
            }
          })
        })
      })
      .catch((error) => {
        console.log(error.message);
        this.snackbar.show = true;
        this.snackbar.color = "red";
        this.snackbar.message =
          "Error la imagen del cupon no se agrego, por favor contacte al Administrador si el problema persiste";
      });
    },
    deleteItem(item) {
      this.$dialog
        .confirm(
          `¿Desea eliminar la imagen <strong>${item.name}</strong>? se eliminara permanentemente`,
          {
            loader: true, // default: false - when set to true, the proceed button shows a loader when clicked.
            // And a dialog object will be passed to the then() callback
          }
        )
        .then((dialog) => {
          this.cuponImageModel.deleteImage(this.cuponId, item.id).then(() => {
              this.cuponImageModel.deleteImgStorage(this.cuponId, item.name).then(() => {
                dialog.close();
                this.snackbar.show = true;
                this.snackbar.color = "green";
                this.snackbar.message = `La imagen se elimino correctamente`;
              })
            })
            .catch((error) => {
              console.error("Error removing document: ", error);
              dialog.close();
              this.snackbar.show = true;
              this.snackbar.color = "red";
              this.snackbar.message = `Error al eliminar la imagen, por favor contacte al Administrador si el problema persiste`;
            });
        })
        .catch((e) => {
          console.log(e);
          // Triggered when cancel button is clicked
          console.log("Delete aborted");
        });
    },
    showSnackbarSuccess(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = "green";
    },
    showSnackbarError(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = "red";
    },
  },
  created() {
    this.$store.commit("SET_LAYOUT", "admin");
  },
  computed: {},
  mounted() {
    this.cuponId = this.$route.params.Id;
    this.cuponModel.findByCupon(this.cuponId).onSnapshot((doc) => {
      this.cupon = doc.data();
    });
    this.cuponImageModel
      .findImage(this.cuponId)
      .onSnapshot((qSnap) => {
        this.image = [];
        qSnap.forEach((doc) => {
          let imagenes = doc.data();
          imagenes.id = doc.id;
          this.image.push(imagenes);
        });
      });
  },
};
</script>
<style>
.file-input-width {
  flex-basis: calc(100% - 200px);
}
</style>