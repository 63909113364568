import BaseModel from './BaseModel';
import firebase from 'firebase/app';
import 'firebase/storage';
import ImageDataConverter from '@/FirestoreDataConverters/Image.js';

export default class Image extends BaseModel {
    constructor(options) {
        super(options);
    }
    addImg(id, obj) { //add img storage 
        var ref = firebase.storage().ref('cupones/' + id).child(obj.name).put(obj);
        return ref;
    }
    getUrlImg(id, name) { // get url of image
        let refdownd = firebase.storage().ref('cupones/' + id + '/' + name).getDownloadURL();
        return refdownd;
    }
    foundNameImageRepit(id, name) { //found repit image name 
        return this.db.collection('cupones').doc(id).collection("cuponesImages").where("name", "==", name).withConverter(ImageDataConverter);
    }
    addImgcollec(id, img, name, date) { // add image in collection of cupons
        let splitNameFile = name.split('.');
        let names = splitNameFile[0];
        let extension = splitNameFile[splitNameFile.length - 1];
        let fullpath = 'cupones/' + id + '/' + names + '.' + extension;
        let upimg = this.db.collection('cupones').doc(id).collection('cuponesImages').add({
            fullpath: fullpath,
            url: img,
            name: name,
            date: date,
        })
        return upimg;
    }
    findImage(id) {
        return this.db.collection("cupones").doc(id).collection("cuponesImages").withConverter(ImageDataConverter);
    }
    deleteImage(id, idimg) { //delete img of collection
        return this.db.collection('cupones').doc(id).collection('cuponesImages').doc(idimg).delete();
    }
    deleteImgStorage(id, name) { // delete img of storage
        let refstorage = firebase.storage().ref('cupones/' + id + '/').child(name).delete();
        return refstorage;
    }
}